
import { defineComponent, PropType, reactive, toRefs, ref, h } from "vue";
import pagaTable from "@/components/pagination-table/index";
import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
import myIcon from "@/components/icon/index";
import formSearch from "@/components/page-search/form";
import { assertAnyTypeAnnotation } from "@babel/types";
import popupWindow from "@/views/components/popupWindow/index.vue";
import { page, deleteCommodity } from "@/api/api"
const purchaseColumns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: TransformCellTextProps) => row.index + 1,
  },
  {
    title: "类别名称",
    dataIndex: "name",
  },
  {
    title: "类别描述",
    dataIndex: "comment",
  },
  {
    title: "创建人",
    dataIndex: "createdBy",
  },
  {
    title: "创建时间",
    dataIndex: "createdTime",
    sorter: true,
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: 200,
    slots: { customRender: "operation" },
  },
];

export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    myIcon,
    popupWindow,
    formSearch,
  },
  setup(props: any) {
    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: {
          span: 16,
        },
      },
      formConfig: [
        {
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "类别名称：",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "name", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
      ],
    });
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys;
    };
    const table = ref();
    const params_api = ref({});
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        params_api.value = {
          "currentPage": params.pageIndex,
          "pageSize": params.pageSize,
          "name": name.value,
          "type": 1,
          sort: params.sort,
        }
        page({ ...params_api.value}).then(res => {
          reslove(res)
        })
      });
    };
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      console.log(pagination, filter, sorter, currentDataSource);
    };
    const handleClick = (text: string, row: any) => {
      Title.value = "修改题库类别";
      popupWindow.value.showModal(row);
    };
    const formSearch = ref();
    const popupWindow = ref();
    const Title = ref();

    const handleAdd = () => {
      Title.value = "新增题库类别";
      popupWindow.value.showModal();
    };
    const name = ref()
    const handleSearch = (formState: any) => {
      name.value = formState.name
      table.value.refresh();
    };
    const delClick = (text: string, row: any) => {
      deleteCommodity({ id: row.categoryId }).then(res => {
        table.value.refresh();
      })
    }
    return {
      ...toRefs(data),
      params_api,
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      Title,
      handleAdd,
      handleSearch,
      delClick,
    };
  },
});
